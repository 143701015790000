.ContainerModal {
  position: fixed;
  top: 50%;
  z-index: 500;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 500px;
  background:#F3F3F3;
  font-family: "Rasa", serif;
  color: #00344b;
  border-radius: 1rem;
}

.ImgContainerModal {
  width: 300px;
  height: 320px;
  margin: 0 auto;
}
.ImgModal {
  max-width: 100%;
  max-height: 100%;
}
.ContainerModal > h3,
.ContainerModal > h4 {
  text-transform: uppercase;
  margin: 0.2rem 0.2rem;
  text-align: center;
}
.BtnContainerModal {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  border-radius: 1rem;
}
.BackButtonModal,
.CartBtnModal {
  color: #00344b;
  border: 2px solid #414141;
  width: 10rem;
  padding: 0.5rem 0.5rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  background: transparent;
  transition-duration: 0.4s;
  cursor: pointer;
  outline: none;
  border-radius: 1rem;
}
.BackButtonModal:hover,
.CartBtnModal:hover {
  /* background: #00344b; */
  color: black;
  transform: scale(1.1);
}
