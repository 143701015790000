.ContainerHome {
  margin: 5rem 2rem 2rem 2rem;
  background: #ffffff;
  height: 100%;
  position: relative;
}
.OverlayHome{
  position: absolute; 
  top:0;
  background: rgba(0, 0, 0, 0.4); 
  z-index: 200;
  width: 100%;
  height: 100%;
}
.ImgHome {
  width: 100%;
  height: 80vh;
  object-fit: cover;
}
.HeadingHome {
  position: absolute;
  top: 20%;
  right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  z-index: 300;
}
.TitleHome {
  color: #ffffff;
  font-family: "Rasa", serif;
  font-size: 3rem;
  font-weight: normal;
  margin: 2rem;
}
@media only screen and (max-width:470px){
  .Title {
    margin:1rem;
    font-size: 2rem;
  }
}

.BtnHome {
  margin-bottom: 1rem;
  color: #ffffff;
  border: 2px solid #ffffff;
  width: 10rem;
  padding: 0.5rem 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  border-radius: 1rem;
  background: transparent;
  transition-duration: 0.4s;
  cursor: pointer;
  outline: none;
  /* background-color: #7ed56f; */
  color: #fff; 

  
}
.BtnHome:hover {
  /* background: #ffffff; */
  color: #7ed56f;
  /* transform: translateY(-3px); */
  transform: scaleX(1.1) scaleY(1.2);

}


.btnHome {
  /* After  */ }
  .btnHome, .btnHome:link, .btnHome:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.5rem 4rem;
    display: inline-block;
    border-radius: 1rem;
    transition: all .2s;
    /* transistion should be in the intial state  */
    position: relative;
    z-index: 1;
    animation: moveInButton .5s ease-out .75s;
    animation-duration: 1s;
    border: none;
    cursor: pointer; }
  .btn:hover, .btn:focus {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
  .btn::after {
    transform: scaleX(1.3) scaleY(1.5);
    opacity: 0;
    animation-name: moveInButton; }
  .btn:active, .btn:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.2); }
  .btn--white {
    background-color: #fff;
    color: #777; }
  .btn--green {
    background-color: #7ed56f;
    color: #fff; }
  .btn::after {
    background-color: #fff; }
  .btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s; }
  .btn--animated {
    animation: moveInButton .5s ease-out .75s;
    animation-fill-mode: backwards; }

.btn-text:link, .btn-text:visited {
  font-size: 1.6rem;
  color: #55c57a;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #55c57a;
  padding: 3px;
  transition: all .4s; }

.btn-text:hover {
  background-color: #55c57a;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  transform: translateY(-2px); }

.btn-text:active {
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); }