.Container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-template-rows: minmax(80vh,1fr);
  margin: 5rem 2rem 2rem 2rem;
  background-color: #ffffff;
}
@media only screen and (max-width:450px){
  .Container{
    margin: 5rem 0 0 0;
  }
}
.ImgContainer {
  margin-top: 2rem;
  object-fit: cover;
  height: 500px;
  overflow: hidden;
  justify-self: center;
}
@media only screen and (max-width:450px){
  .ImgContainer {
    width: 100%;
  }
}
.Img {
  display: inline-block;
  max-width: 400px;
  max-height: 500px;
}
.InfoContainer {
  font-family: "Rasa", serif;
  color: #00344b;
  margin: 2rem;
}
.Title {
  font-size: 2rem;
  margin: auto;
}
.BtnContainer {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;

}
.BackButton,
.CartBtn {
  color: #00344b;
  border: 2px solid #414141;
  width: 10rem;
  padding: 0.5rem 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  background: transparent;
  transition-duration: 0.4s;
  cursor: pointer;
  outline: none;
  border-radius: 2rem;
}
.BackButton:hover,
.CartBtn:hover {
  background: #00344b;
  color: white;
  transform: scaleX(1.1) scaleY(1.2);
}
.CartBtnDisable {
  color: #00344b;
  border: 2px solid #414141;
  width: 10rem;
  padding: auto 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  opacity: 0.6;
  cursor: not-allowed;
  border-radius:2rem;
}
