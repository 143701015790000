.TableContainerCartList {
  width: 100%;
 
}
.CartTableCartList {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  font-family: "Rasa", serif;
  color: #00344b;
  
}
.CartTableCartList thead {
  text-transform: uppercase;
  text-align: center;
 
}
.CartTableCartList th {
  width: 10%;
}
.CartTableCartList td {
  text-align: center;
  border-bottom: 1px solid #c3c3c3;
}

@media only screen and (max-width: 569px) {
  .CartTableCartList {
    font-size: 0.7rem;
  }
}
