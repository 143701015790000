
.CardProduct {
  width: 100%;
  background-color:FFEFEA;
  /* background-color: #fff; */
  display: grid;
  grid-template-rows: auto auto 4em;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
  border-radius: 2rem;
}
.CardProduct a {
  text-decoration: none;
}
.ImgContainerProduct {
  width: 100%;
  height: 320px;
  object-fit: cover;
  overflow: hidden;
  /* background-color: darkblue; */
  /* display:none; */
}

.ImgProduct {
  display: block;
  max-width: 90%;
  height: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;
  
}
.TitleProduct {
  color: #00344b;
  text-align: center;
  width: 250px;
  margin: 0.5rem auto;
  font-size: 1.5rem;
  font-family: "Rasa", serif;
  font-weight: normal;
}
.PriceProduct {
  color: #00344b;
  font-size: 1.5rem;
  justify-self: center;
  margin-bottom: 0.5rem;
}
.CartBtnProduct {
  justify-self: center;
  margin-bottom: 1rem;
  color: #00344b;
  border: 2px solid #414141;
  width: 10rem;
  padding: auto 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  background: transparent;
  transition-duration: 0.4s;
  cursor: pointer;
  outline: none;
  border-radius: 2rem;
}
.CartBtnProduct:hover {
  background: transparent;
  /* color: white; */
  transform: scaleX(1.1) scaleY(1.1);
}

.CartBtnDisableProduct {
  justify-self: center;
  margin-bottom: 1rem;
  color: #00344b;
  border: 2px solid #414141;
  width: 10rem;
  padding: auto 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  opacity: 0.6;
  cursor: not-allowed;
  border-radius: 2rem;
}
