.NavigationItems {
  list-style: none;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
 
}


.CartItems{
    width: 30px; 
}
@media (max-width: 768px) {
  .NavigationItems li:not(:last-child){
    display: none;
  }
}