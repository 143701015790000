.PageContainer{
    margin:3rem;
}
@media only screen and (max-width:450px){
    .PageContainer{
      margin:0.2rem;
    }
  }
.ProductContainer{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-gap:1.5rem;
    justify-items: center;
   
}