@import url(https://fonts.googleapis.com/css?family=Rasa);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}





.Backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top:0;
  left:0;
  background: rgba(0, 0, 0, 0.6);
  z-index:400;
  
}


.Title{
    color: #00344B ;
    font-family: 'Rasa', serif;
    font-size: 3rem;
    font-weight: normal;
    margin: 2rem;
}
.BtnContainerCartItem {
  font-size: 2rem;
  
}
.BtnCartItem {
  background: transparent;
  border: 1px solid black;
  font-size: 1.5rem;
  width: 30px;
  outline: none;
  cursor: pointer;
}
.BtnSpanCartItem {
  margin: 0 1rem;
}
.RemoveBtnCartItem {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  width: 30px;
  outline: none;
  cursor: pointer;
}
@media only screen and (max-width: 649px) {
    .BtnContainerCartItem {
        font-size: 0.8rem;
      }
  .RemoveBtnCartItem,
  .BtnCartItem {
    font-size: 0.8rem;
  }
  .BtnSpanCartItem {
    margin: 0 0;
  }
}

.TableContainerCartList {
  width: 100%;
 
}
.CartTableCartList {
  width: 100%;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  font-family: "Rasa", serif;
  color: #00344b;
  
}
.CartTableCartList thead {
  text-transform: uppercase;
  text-align: center;
 
}
.CartTableCartList th {
  width: 10%;
}
.CartTableCartList td {
  text-align: center;
  border-bottom: 1px solid #c3c3c3;
}

@media only screen and (max-width: 569px) {
  .CartTableCartList {
    font-size: 0.7rem;
  }
}

.ContainerTotals {
  margin: 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: "Rasa", serif;
  color: #00344b;
}
.BtnTotals {
  color: #00344b;
  border: 2px solid #414141;
  width: 10rem;
  padding: 0.5rem 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  background: transparent;
  transition-duration: 0.4s;
  cursor: pointer;
  outline: none;
}

.BtnTotals:hover {
  background: #00344b;
  color: white;
  transform: scaleX(1.1) scaleY(1.2);
}
.ContainerTotals h3 {
  margin: 0.5rem 0.5rem;
  text-transform: uppercase;
}

.ContainerCart {
    margin: 5rem 2rem 2rem 2rem;
    background-color: #ffffff;
    height: 100%;
  }

  @media only screen and (max-width:569px){
    .Container{
      margin: 5rem 0 0 0;
    }
  }
.Container {
  margin: 5rem 2rem 2rem 2rem;
  background-color: #ffffff;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Rasa", serif;
  color: #00344b;
  font-style: normal;
  font-size: 1.5rem;
}

.Container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-template-rows: minmax(80vh,1fr);
  margin: 5rem 2rem 2rem 2rem;
  background-color: #ffffff;
}
@media only screen and (max-width:450px){
  .Container{
    margin: 5rem 0 0 0;
  }
}
.ImgContainer {
  margin-top: 2rem;
  object-fit: cover;
  height: 500px;
  overflow: hidden;
  justify-self: center;
}
@media only screen and (max-width:450px){
  .ImgContainer {
    width: 100%;
  }
}
.Img {
  display: inline-block;
  max-width: 400px;
  max-height: 500px;
}
.InfoContainer {
  font-family: "Rasa", serif;
  color: #00344b;
  margin: 2rem;
}
.Title {
  font-size: 2rem;
  margin: auto;
}
.BtnContainer {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;

}
.BackButton,
.CartBtn {
  color: #00344b;
  border: 2px solid #414141;
  width: 10rem;
  padding: 0.5rem 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  background: transparent;
  transition-duration: 0.4s;
  cursor: pointer;
  outline: none;
  border-radius: 2rem;
}
.BackButton:hover,
.CartBtn:hover {
  background: #00344b;
  color: white;
  transform: scaleX(1.1) scaleY(1.2);
}
.CartBtnDisable {
  color: #00344b;
  border: 2px solid #414141;
  width: 10rem;
  padding: auto 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  opacity: 0.6;
  cursor: not-allowed;
  border-radius:2rem;
}

.ContainerHome {
  margin: 5rem 2rem 2rem 2rem;
  background: #ffffff;
  height: 100%;
  position: relative;
}
.OverlayHome{
  position: absolute; 
  top:0;
  background: rgba(0, 0, 0, 0.4); 
  z-index: 200;
  width: 100%;
  height: 100%;
}
.ImgHome {
  width: 100%;
  height: 80vh;
  object-fit: cover;
}
.HeadingHome {
  position: absolute;
  top: 20%;
  right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  z-index: 300;
}
.TitleHome {
  color: #ffffff;
  font-family: "Rasa", serif;
  font-size: 3rem;
  font-weight: normal;
  margin: 2rem;
}
@media only screen and (max-width:470px){
  .Title {
    margin:1rem;
    font-size: 2rem;
  }
}

.BtnHome {
  margin-bottom: 1rem;
  color: #ffffff;
  border: 2px solid #ffffff;
  width: 10rem;
  padding: 0.5rem 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  border-radius: 1rem;
  background: transparent;
  transition-duration: 0.4s;
  cursor: pointer;
  outline: none;
  /* background-color: #7ed56f; */
  color: #fff; 

  
}
.BtnHome:hover {
  /* background: #ffffff; */
  color: #7ed56f;
  /* transform: translateY(-3px); */
  transform: scaleX(1.1) scaleY(1.2);

}


.btnHome {
  /* After  */ }
  .btnHome, .btnHome:link, .btnHome:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.5rem 4rem;
    display: inline-block;
    border-radius: 1rem;
    transition: all .2s;
    /* transistion should be in the intial state  */
    position: relative;
    z-index: 1;
    -webkit-animation: moveInButton .5s ease-out .75s;
            animation: moveInButton .5s ease-out .75s;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    border: none;
    cursor: pointer; }
  .btn:hover, .btn:focus {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
  .btn::after {
    transform: scaleX(1.3) scaleY(1.5);
    opacity: 0;
    -webkit-animation-name: moveInButton;
            animation-name: moveInButton; }
  .btn:active, .btn:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.2); }
  .btn--white {
    background-color: #fff;
    color: #777; }
  .btn--green {
    background-color: #7ed56f;
    color: #fff; }
  .btn::after {
    background-color: #fff; }
  .btn::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all .4s; }
  .btn--animated {
    -webkit-animation: moveInButton .5s ease-out .75s;
            animation: moveInButton .5s ease-out .75s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards; }

.btn-text:link, .btn-text:visited {
  font-size: 1.6rem;
  color: #55c57a;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid #55c57a;
  padding: 3px;
  transition: all .4s; }

.btn-text:hover {
  background-color: #55c57a;
  color: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  border-radius: 50px;
  transform: translateY(-2px); }

.btn-text:active {
  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); }
.ContainerModal {
  position: fixed;
  top: 50%;
  z-index: 500;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 500px;
  background:#F3F3F3;
  font-family: "Rasa", serif;
  color: #00344b;
  border-radius: 1rem;
}

.ImgContainerModal {
  width: 300px;
  height: 320px;
  margin: 0 auto;
}
.ImgModal {
  max-width: 100%;
  max-height: 100%;
}
.ContainerModal > h3,
.ContainerModal > h4 {
  text-transform: uppercase;
  margin: 0.2rem 0.2rem;
  text-align: center;
}
.BtnContainerModal {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  border-radius: 1rem;
}
.BackButtonModal,
.CartBtnModal {
  color: #00344b;
  border: 2px solid #414141;
  width: 10rem;
  padding: 0.5rem 0.5rem;
  margin-top: 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  background: transparent;
  transition-duration: 0.4s;
  cursor: pointer;
  outline: none;
  border-radius: 1rem;
}
.BackButtonModal:hover,
.CartBtnModal:hover {
  /* background: #00344b; */
  color: black;
  transform: scale(1.1);
}

.ToggleButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 30px;
  width: 36px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}
.ToggleButton:focus {
  outline: none;
}

.ToggleButttonLine {
  width: 30px;
  height: 3px;
  background: #476c7d;
}

.LogoLogo{
    width:3rem;
    height:3rem;
   }

.NavigationItem {
  margin: 0 2.5rem;
  font-size: 20px;
}

.NavigationItem a {
 
  color: #fff;
  text-decoration: none;
  position: relative;
}
.NavigationItem a:hover {
  color: #019FFD;
}

.NavigationItem > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #476c7d;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
.NavigationItem > a:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.CartItem {
  flex-grow: 1;
  font-size: 20px;
  margin:0 5px;
}
.CartItem a {
  float: right;
  display: block;
  margin:0;
  padding: 0;
}

.NavigationItems {
  list-style: none;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
 
}


.CartItems{
    width: 30px; 
}
@media (max-width: 768px) {
  .NavigationItems li:not(:last-child){
    display: none;
  }
}
.Navbar {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0;
  background-color:#2A2B72;

  height: 70px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
  
  
}

.Navigation {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 2rem;
  
}

.LogoNav {
  margin-left: 0.5rem;
}

@media (min-width: 769px) {
  .ToggleButton {
    display: none;
  }
}


.CardProduct {
  width: 100%;
  background-color:FFEFEA;
  /* background-color: #fff; */
  display: grid;
  grid-template-rows: auto auto 4em;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
  border-radius: 2rem;
}
.CardProduct a {
  text-decoration: none;
}
.ImgContainerProduct {
  width: 100%;
  height: 320px;
  object-fit: cover;
  overflow: hidden;
  /* background-color: darkblue; */
  /* display:none; */
}

.ImgProduct {
  display: block;
  max-width: 90%;
  height: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 1rem;
  
}
.TitleProduct {
  color: #00344b;
  text-align: center;
  width: 250px;
  margin: 0.5rem auto;
  font-size: 1.5rem;
  font-family: "Rasa", serif;
  font-weight: normal;
}
.PriceProduct {
  color: #00344b;
  font-size: 1.5rem;
  justify-self: center;
  margin-bottom: 0.5rem;
}
.CartBtnProduct {
  justify-self: center;
  margin-bottom: 1rem;
  color: #00344b;
  border: 2px solid #414141;
  width: 10rem;
  padding: auto 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  background: transparent;
  transition-duration: 0.4s;
  cursor: pointer;
  outline: none;
  border-radius: 2rem;
}
.CartBtnProduct:hover {
  background: transparent;
  /* color: white; */
  transform: scaleX(1.1) scaleY(1.1);
}

.CartBtnDisableProduct {
  justify-self: center;
  margin-bottom: 1rem;
  color: #00344b;
  border: 2px solid #414141;
  width: 10rem;
  padding: auto 0.5rem;
  text-transform: uppercase;
  font-size: 1rem;
  opacity: 0.6;
  cursor: not-allowed;
  border-radius: 2rem;
}

.PageContainer{
    margin:3rem;
}
@media only screen and (max-width:450px){
    .PageContainer{
      margin:0.2rem;
    }
  }
.ProductContainer{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-gap:1.5rem;
    justify-items: center;
   
}
.SideDrawer {
  height: 100vh;
  background-color: #2A2C72;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  
  top: 0;
  left: 0;
  width: 70%;
  max-width: 300px;
  z-index: 600;
  transform: translateX(-100%);
  transition: transform 0.5s ease-out;
}
.OpenSide {
  transform: translateX(0);
}

.NavigationItemsSide {
  list-style-type: none;
  margin-top: 50px;
  text-align: center;
 
}
.NavigationItemsSide li {
  margin: 10px;
}
.LogoSide {
  margin: 20px;
  text-align: center;
}

@media (min-width: 769px) {
  .SideDrawer {
    display: none;
  }
}

.App{
    /* background-color: #EEEDE7; */
    height:100%;
    /* background-color:#FFEFEA; */
    background-color: #F3F3F3;
 
 
   
}
.ContainerApp{
 margin-top: 90px;

}
