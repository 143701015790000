.ToggleButton {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 30px;
  width: 36px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}
.ToggleButton:focus {
  outline: none;
}

.ToggleButttonLine {
  width: 30px;
  height: 3px;
  background: #476c7d;
}
